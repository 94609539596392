<template>
  <form @submit.prevent="save">
    <div class="row">
      <div class="col-12">
        <h5>DADOS DE PAGAMENTO:</h5>
      </div>
      <div class="col-12">
        <p>
          <strong>CONDIÇÃO DE PAGAMENTO: </strong>
          {{ orderData.paymentCondition.descriptionCpg }}
        </p>
        <p>
          <strong>FORMA DE PAGAMENTO: </strong>
          {{ this.orderData.paymentCondition.formPayment }}
        </p>
      </div>
      <div v-if="this.orderData.paymentCondition.descriptionCpg == null">
        <div
          class="alert alert-warning border-0 alert-dismissible fade show mt-3"
        >
          <span class="fw-semibold">Atenção!</span> <br />Condição de pagamento
          indisponível.
          <br />
          Contate o comercial para adicionar condições de pagamento.
        </div>
      </div>
      <div v-else class="col-12 text-end">
        <button
          type="button"
          class="btn btn-primary btn-lg me-2"
          @click="backToPreviousStep()"
        >
          ALTERAR TRANSPORTE
        </button>
        <button class="btn btn-secondary btn-lg" :disabled="disableButton">
          {{ disableButton ? 'REALIZANDO PEDIDO, AGUARDE' : 'CONCLUIR PEDIDO' }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'SelectPaymentCondition',
  props: {
    orderData: {
      type: Object,
      required: true,
    },
    disableButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    backToPreviousStep() {
      this.$emit('backToPreviousStep')
    },
    async save() {
      try {
        this.$emit('setPaymentCondition', {
          codCpg: this.orderData.paymentCondition.codCpg,
          descriptionCpg: this.orderData.paymentCondition.descriptionCpg,
          formPayment: this.orderData.paymentCondition.formPayment,
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
}
</script>
